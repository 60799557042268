@font-face {
  font-family: 'Inter';
  src: url('../public/fonts/Inter/Inter-VariableFont_wght.ttf') format('truetype');
}

@font-face {
  font-family: 'SpaceMono';
  src: url('../public/fonts/SpaceMono/SpaceMono-Regular.ttf') format('truetype');
}

html,
body {
  font-family: Inter, serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  font-style: normal;
  margin: 0;
  height: 100%;
  background-color: #F0F7FC;
}

#root {
  height: 100%;
}
/*https://github.com/ant-design/ant-design/issues/26758*/
.ant-picker-dropdown .ant-picker-panel-container .ant-picker-panels {
  display: inline-flex;
  animation: none;
  flex-wrap: nowrap;
}

@media (max-width: 900px) {
  .ant-picker-dropdown .ant-picker-panel-container .ant-picker-panels {
    display: inline-grid;
    width: 100%;
    animation: none;
    flex-wrap: nowrap;
    direction: ltr;
  }
}
